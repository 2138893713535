import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link"
import Layout from "../components/constructs/layout"
import P from "../components/primitives/p"
import theme from "../config/theme"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const StyledSelect = styled.select`
  width: 100%;
  font-size: 16px;
  background: white;
  padding: 4px;
  border-radius: 4px;
`

const FittedSelect = styled(StyledSelect)`
  width: fit-content;
`

const StyledLabel = styled.label`
  display: block;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
`

const InlineLabel = styled(StyledLabel)`
  display: inline-block;
  margin: 4px;
`

const InlineRadioLabel = styled(StyledLabel)`
  display: inline-block;
  font-size: 16px;
  margin-right: 16px;
  color: white;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: ${theme.colors.blue2};
`

const Section = styled.div`
  width: 100%;
  margin: 8px 0;
`

const RadioButton = styled.input`
  height: 18px;
  width: 18px;
  margin: 5px;
`

const StyledInput = styled.input`
  background: white;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
`

const StyledTextArea = styled.textarea`
  background: white;
  font-size: 16px;
  width: 100%;
  border-radius: 4px;
`

const StyledDateInput = styled(StyledInput)`
  width: fit-content;
`

const StyledTimeInput = styled(StyledInput)`
  width: fit-content;
`

const SubmitButton = styled.button`
  background-image: linear-gradient(-180deg, #00d775, #00bd68);
  margin: 0 auto;
  border-radius: 10px;
  height: 48px;
  width: 100px;
  font-size: 20px;
  margin-top: 8px;
  color: white;
  font-weight: 600px;
  :hover {
    background-image: linear-gradient(-180deg, #00d275, #00b768);
  }
`

let today = new Date()

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: undefined,
      email: undefined,
      isReturn: false,
      origin: "Skiathos",
      destination: "Loutraki",
      departureDate: undefined,
      departureTime: undefined,
      returnDate: undefined,
      returnTime: undefined,
      message: undefined,
      passengers: "1",
    }
  }
  render() {
    const handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
      e.preventDefault()
      const form = e.target
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error))
    }

    const { isReturn } = this.state

    return (
      <Layout
        title="SeaCab: Enquiry page for the faster water-taxi from Skiathos to Skopelos"
        description="Book your water-taxi from Skiathos to Skopelos quickly and easily. Why wait to fit into the ferry schedule? Get there now."
      >
        <Container>
          <form
            name="enquiry"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="enquiry" />
            <P hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </P>
            <Section>
              <InlineRadioLabel for="oneway">
                <RadioButton
                  onChange={handleChange}
                  type="radio"
                  name="isReturn"
                  value="false"
                  required
                />
                One way
              </InlineRadioLabel>
              <InlineRadioLabel for="return">
                <RadioButton
                  onChange={handleChange}
                  type="radio"
                  name="isReturn"
                  value="true"
                />
                Return
              </InlineRadioLabel>
            </Section>
            <Section>
              <StyledLabel>From</StyledLabel>
              <StyledSelect
                name="origin"
                onChange={handleChange}
                defaultValue="skiathos"
              >
                <option value="skiathos">Skiathos</option>
                <option value="loutraki">Loutraki (Glossa)</option>
                <option value="adrina">Adrina Hotel/Spa</option>
                <option value="elios">Elios</option>
                <option value="agnodas">Agnodas</option>
              </StyledSelect>
            </Section>
            <Section>
              <StyledLabel>To</StyledLabel>
              <StyledSelect
                name="destination"
                onChange={handleChange}
                defaultValue="loutraki"
              >
                <option value="skiathos">Skiathos</option>
                <option value="loutraki">Loutraki (Glossa)</option>
                <option value="adrina">Adrina Hotel/Spa</option>
                <option value="elios">Elios</option>
                <option value="agnodas">Agnodas</option>
              </StyledSelect>
            </Section>
            <Section>
              <StyledLabel>Depart (Operating hours 08:30 - 20:30)</StyledLabel>
              <StyledDateInput
                type="date"
                name="departureDate"
                onChange={handleChange}
                defaultValue={`${today.getFullYear()}-${(
                  "0" +
                  (today.getMonth() + 1)
                ).slice(-2)}-${today.getDate()}`}
              />
              <InlineLabel>at</InlineLabel>
              <StyledTimeInput
                type="time"
                name="departureTime"
                onChange={handleChange}
                step={1800}
                defaultValue="08:30"
                min="08:30"
                max="20:30"
              />
            </Section>
            <Section>
              <StyledLabel>Return</StyledLabel>
              <StyledDateInput
                style={{
                  background: isReturn !== "true" ? "#cfd4d1" : "white",
                }}
                type="date"
                name="returnDate"
                onChange={handleChange}
                disabled={isReturn !== "true"}
                defaultValue={`${today.getFullYear()}-${(
                  "0" +
                  (today.getMonth() + 1)
                ).slice(-2)}-${today.getDate()}`}
              />
              <InlineLabel>at</InlineLabel>
              <StyledTimeInput
                disabled={isReturn !== "true"}
                style={{
                  background: isReturn !== "true" ? "#cfd4d1" : "white",
                }}
                type="time"
                name="returnTime"
                onChange={handleChange}
                step={1800}
                defaultValue="08:30"
                min="08:30"
                max="20:30"
              />
            </Section>
            <Section>
              <StyledLabel>Full name</StyledLabel>
              <StyledInput
                type="text"
                name="name"
                onChange={handleChange}
                required
              />
            </Section>
            <Section>
              <StyledLabel>Email</StyledLabel>
              <StyledInput
                type="email"
                name="email"
                onChange={handleChange}
                required
              />
            </Section>
            <Section>
              <StyledLabel>Mobile number</StyledLabel>
              <StyledInput
                type="tel"
                name="mobile"
                onChange={handleChange}
                required
              />
            </Section>
            <Section>
              <StyledLabel>Passengers</StyledLabel>
              <FittedSelect
                style={{ width: "fit-content" }}
                name="passengers"
                onChange={handleChange}
                defaultValue="1"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </FittedSelect>
            </Section>
            <Section>
              <StyledLabel>Any comments? </StyledLabel>
              <StyledTextArea name="message" onChange={handleChange} />
            </Section>
            <Section style={{ display: "flex" }}>
              <SubmitButton type="submit">Submit</SubmitButton>
            </Section>
          </form>
        </Container>
      </Layout>
    )
  }
}

export default EnquiryForm
